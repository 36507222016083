<template>
<div class="myteam">
    <div class="header" v-if="$route.params.paddress">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">{{ $route.params.paddress }}</span>
    </div>
    <div class="header" v-else>
        <a-icon @click="$router.go(-1)" type="left" />
        <span class="title">{{$t('team.title')}}</span>
        <!-- <a-icon @click="$router.push({name:'share'})" type="usergroup-add" /> -->
    </div>
<!-- <div class="flex top" >
        <div class="total">
            Total: 87913
        </div>
        <a-icon v-if="!expandSearch" type="search" @click="expandSearch=!expandSearch" />
        <div v-else>
            <a-input class="search-ipt" allowClear v-model="skey" @change="onChange" :placeholder="$t('team.searchPlaceholder')" @blur="expandSearch=!expandSearch">
                <a-icon slot="prefix" type="search" />
            </a-input>
        </div>
    </div> -->
    
    <div v-for="s,i in data" :key="'s'+i" class="box">
        <!-- <span class="tag">{{$t('wallet.depositNodes')[s.defi_level]}}</span> -->
        <div class="up flex">
            <div class="address">
                <div class="title bd primary"><span>{{s.address.substring(0,13)+'...'+s.address.substring(27,34)}}</span></div>
                <div class="label"><span>{{$t('team.regTime')}}</span><span>{{s.created_at}}</span></div>
            </div>
            <div>
                <span class="bd">{{s.sons_count}}</span>
                <a-icon class="arrow" type="caret-right" @click="goInvites(s.address)" />
            </div>
        </div>
    </div>
    <div v-if="showLoadingMore"
        :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
    >
        <a-spin v-if="loading" />
        <a-button v-else type="dashed" ghost @click="getData(true)">
            {{$t('common.more')}}
        </a-button>
    </div>
    <div id="inviteBtn">
        <a-icon class="icon" @click="$router.push({name:'share'})" type="usergroup-add" />
    </div>
</div>
  
</template>
<script>

export default {
  name: "Invites",
  data() {
    return {
      loading: true,
      showLoadingMore: true,
      expandSearch: true,
      page:1,
      pageSize: 20,
      asc: 0,
      data: [],
      skey:'',
      paddress: this.$route.params.paddress,
    };
  },
  mounted() {
    this.getData(false);
  },
  methods: {
    getData(append = true){
        this.loading = true;
        this.$http.call(this.$http.api.teams,{
            data:{page:this.page,size:this.pageSize,sort:this.asc,skey:this.skey,paddress:this.paddress}
        }).then(res=>{
            // console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.page++;
                if(append){
                    this.data = this.data.concat(resp.data);
                }else{
                    this.data = resp.data;
                }
                if(resp.data.length<this.pageSize){
                    this.showLoadingMore = false;
                }
            }else{
                this.$message.error(resp.data);
            } 
            
        },res=>{
            console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(()=>{
            
        });
    },
    onSearch(value){
        console.log(value,this.skey)
        this.skey = value;
        this.page = 1;
        this.getData(false);
    },
    onChange(){
        // console.log(value,this.skey)
        // this.skey = value;
        this.page = 1;
        this.getData(false);
    },
    changeAsc(){
        this.asc = !this.asc;
        this.page = 1;
        this.getData(false);
    },
    goRecords(address){
        this.$router.push({name: 'records', params:{address: address}})
    },
    goInvites(address){
        this.$router.push({name: 'invites', params:{paddress: address}})

    }
  },
};
</script>
<style lang="less">
.myteam{
    padding: 70px 10px;
    width: 100%;
    .header{
        padding: 0 15px;
    }
    .top{
        display: flex;
        align-items: center;
        color: @white;
        .total{
            // width: 40%;
            // margin-right: 15px;
            text-align: left;
        }
        .search-ipt{
            // .ant-input{
            //     padding: 13px 20px 13px 38px;
            //     height: auto;
            //     border: 0;
            // }
            // .ant-input-suffix{
            //     color: @white;
            // }
            // .ant-input-search-icon{
            //     color: @white;
            // }
            // .ant-input-clear-icon{
            //     color: @white;
            // }
        }
    }

    .box {
        padding: 20px;
        // margin: 20px 0;
        margin-bottom: 20px;
        font-size: 14px;
        position:relative;
        overflow: hidden;
        .tag{
            position: absolute;
            top: 0;
            right:0;
            background: @purple;
            padding: 2px 10px;
            font-size: 0.7rem;
            border-bottom-left-radius: 15px;
        }
        .up{
            padding-bottom: 15px;
            .address{
                text-align: left;
                .title{
                    font-size: 16px;
                }
            }
        }
        .bottom{
            text-align: left;
            .bd-box{
                border: 1px solid @white*0.5;
                border-radius: 6px;
                padding: 6px;
            }
            .bd-box-primary{
                border: 1px solid @primary-color-shadow;
                border-radius: 6px;
                // padding: 8px 20px;
                flex: 1;
                .text.flex{
                    align-items: flex-start;
                }
            }
            .bd-box-purple{
                border: 1px solid @purple;
                border-radius: 6px;
                // padding: 8px 20px;
                // display:flex;
            }
            .std-icon{
                width: 24px;
            }
        }
        .label{
            font-size: 12px;
        }
        .bd{
            font-weight: 700;
        }
        .primary{
            color: @primary-color;
        }
    }.myTotal{
        color: @primary-color;
        padding: 20px;
        // justify-content: space-between;
    }
    #inviteBtn {
        position: fixed;
        bottom: 20px; right: 10px;
        background: rgba(150,150,150,0.5);
        border-radius: 50%;
        width: 70px; height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            font-size: 30px;
            color: @primary-color;
        }
    }
}
</style>
